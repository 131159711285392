// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import axios from "axios"
import VueSignature from "vue-signature-pad";
import App from "./App";

import './assets/styles/global.css'

Vue.use(VueSignature);
Vue.config.productionTip = false;
Vue.prototype.$axios = axios

new Vue({
  render: (h) => h(App)
}).$mount("#app");

document.title = '汉医公社会员签字'