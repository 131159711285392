<template>
  <div id="app">
    <div class="container">
      <div class="container">
        <VueSignaturePad
          id="signature"
          width="100%"
          height="300px"
          ref="signaturePad"
          :options="options"
        />
      </div>
      <div class="msg" v-show="msg">
        {{ msg }}
      </div>
      <div class="buttons">
        <button class="sign-btn" @click="undo">回退</button>
        <button class="sign-btn" @click="save">确定</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    options: {
      penColor: "#000",
    },
    msg: "",
  }),
  methods: {
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
    save() {
      this.msg = "";
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      let params = new URLSearchParams(window.location.search);
      let token = params.get("tk");
      let xt = params.get("xt");
      if (!token) {
        this.msg = "请重新扫码签字";
        return;
      }

      if (isEmpty) {
        this.msg = "请在上方签字框中签字";
        return;
      }
      let param = new FormData(); // 创建form对象
      param.append("file", this.base64toBlob(data.split(",")[1], "image/png")); // 通过append向form对象添加数据

      param.append("bc", token); // 添加form表单中其他数据

      let config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      if (xt) {
        config.headers["X-Access-Token"] = xt;
      }

      this.$axios.post("/dhp/sys/common/sup", param, config).then((res) => {
        // console.log(res)
        if (res.data.success) {
          this.msg = "已签字";
        } else {
          this.msg = res.data.message;
        }
      });
    },
    change() {
      this.options = {
        penColor: "#00f",
      };
    },
    resume() {
      this.options = {
        penColor: "#c0f",
      };
    },
    // 将base64转换为blob
    base64toBlob(base64Data, contentType) {
      contentType = contentType || "";
      var sliceSize = 1024;
      var byteCharacters = atob(base64Data);
      var bytesLength = byteCharacters.length;
      var slicesCount = Math.ceil(bytesLength / sliceSize);
      var byteArrays = new Array(slicesCount);

      for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        var begin = sliceIndex * sliceSize;
        var end = Math.min(begin + sliceSize, bytesLength);

        var bytes = new Array(end - begin);
        for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
          bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
      }
      return new Blob(byteArrays, { type: contentType });
    },
  },
};
</script>

<style>
#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #4bc5e8, #369197);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.container {
  width: "100%";
  padding: 8px 16px;
}

.buttons {
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-top: 8px;
}
.sign-btn {
  width: 130px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  background-color: var(--login-red);
  font-size: 24px;
  color: #4bc5e8;
  border-radius: 32px;
  border-color: #4bc5e8;
  cursor: pointer;
}
.msg {
  color: #ad2930;
  text-align: center;
  margin: 10px 0;
  font-size: 20px;
}
</style>
